<template>
  <div class="container page">
    <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Forgot Password</div>
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" id="email" v-model="email" placeholder="Enter email">
                </div>
                <button type="submit" class="btn btn-block btn-primary">Send Password Reset Link</button>
              </form>
            </div>
          </div>
          <p>&nbsp;</p>
          <p>Remembered your password? <router-link to="/login">Log in</router-link></p>
          <p>Don’t have an account? <router-link to="/register">Register now!</router-link></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Forgot Password",
    components: {  },
    data() {
      return {
        email: "",
      };
    },
    methods: {
      submitForm() {
        // Handle forgot password logic here
        this.$http.get("/sanctum/csrf-cookie").then(() => {
          this.$http
            .post("/forgot-password", {
              email: this.email,
            })
            .then(() => {
              // Reset password link sent successfully
              // Show success message or redirect to a confirmation page
              this.$toast.success("The password reset link was sent to your email address. Please check your inbox and/or your spam folder.");
            })
            .catch((error) => {
              // Failed to send reset password link
              // Handle or display the error message
              console.error(error);
            });
        });
      },
    },
}
</script>

<style lang="scss" scoped>
  
</style>
